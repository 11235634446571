export function getBaseUrls() {
    const hostname = window.location.hostname;

    const environments = {
        staging: ['staging', 'localhost'],
        dev: ['dev', 'beta'],
        production: [] // Default case
    };

    // Identify the environment
    let env = 'production'; // Default to production
    if (environments.staging.some(envName => hostname.includes(envName))) {
        env = 'staging';
    } else if (environments.dev.some(envName => hostname.includes(envName))) {
        env = 'dev';
    }

    // Define base URLs
    const config = {
        staging: {
            baseURL: process.env.VUE_APP_API_BASE_URL_STAGING,
            inboxBaseURL: process.env.VUE_APP_INBOX_API_BASE_URL_STAGING,
            integrationBaseURL: process.env.VUE_APP_INTEGRATIONS_API_BASE_URL_STAGING,
            teamsBaseURL: process.env.VUE_APP_TEAMS_API_BASE_URL_STAGING,
            aiBaseURL: process.env.VUE_APP_AI_API_BASE_URL_STAGING,
            logEventsBaseURL: process.env.VUE_APP_EVENT_HISTORY_URL_STAGING,
            webSocketBaseURL: process.env.VUE_APP_WEBSOCKET_STAGING,
            widgetBaseURL: process.env.VUE_APP_WIDGET_BASE_URL_STAGING,
            automationsBaseURL: process.env.VUE_APP_AUTOMATIONS_URL_STAGING,
            formBuilderBaseURL: process.env.VUE_APP_FORMBUILDER_URL_STAGING
        },
        dev: {
            baseURL: process.env.VUE_APP_API_BASE_URL_STAGING,
            inboxBaseURL: process.env.VUE_APP_INBOX_API_BASE_URL_STAGING,
            integrationBaseURL: process.env.VUE_APP_INTEGRATIONS_API_BASE_URL_STAGING,
            teamsBaseURL: process.env.VUE_APP_TEAMS_API_BASE_URL_STAGING,
            aiBaseURL: process.env.VUE_APP_AI_API_BASE_URL_STAGING,
            logEventsBaseURL: process.env.VUE_APP_EVENT_HISTORY_URL_STAGING,
            webSocketBaseURL: process.env.VUE_APP_WEBSOCKET_STAGING,
            widgetBaseURL: process.env.VUE_APP_WIDGET_BASE_URL_DEV,
            automationsBaseURL: process.env.VUE_APP_AUTOMATIONS_URL_STAGING,
            formBuilderBaseURL: process.env.VUE_APP_FORMBUILDER_URL_STAGING
            // baseURL: process.env.VUE_APP_API_BASE_URL_DEV,
            // inboxBaseURL: process.env.VUE_APP_INBOX_API_BASE_URL_DEV,
            // integrationBaseURL: process.env.VUE_APP_INTEGRATIONS_API_BASE_URL_DEV,
            // teamsBaseURL: process.env.VUE_APP_TEAMS_API_BASE_URL_DEV,
            // aiBaseURL: process.env.VUE_APP_AI_API_BASE_URL_DEV,
            // logEventsBaseURL: process.env.VUE_APP_EVENT_HISTORY_URL,
            // webSocketBaseURL: process.env.VUE_APP_WEBSOCKET_DEV,
            // widgetBaseURL: process.env.VUE_APP_WIDGET_BASE_URL_DEV,
            // automationsBaseURL: process.env.VUE_APP_AUTOMATIONS_URL_DEV,
            // formBuilderBaseURL: process.env.VUE_APP_FORMBUILDER_URL_DEV
        },
        production: {
            baseURL: process.env.VUE_APP_API_BASE_URL,
            inboxBaseURL: process.env.VUE_APP_INBOX_API_BASE_URL,
            integrationBaseURL: process.env.VUE_APP_INTEGRATIONS_API_BASE_URL,
            teamsBaseURL: process.env.VUE_APP_TEAMS_API_BASE_URL,
            aiBaseURL: process.env.VUE_APP_AI_API_BASE_URL,
            logEventsBaseURL: process.env.VUE_APP_EVENT_HISTORY_URL,
            webSocketBaseURL: process.env.VUE_APP_WEBSOCKET,
            widgetBaseURL: process.env.VUE_APP_WIDGET_BASE_URL,
            automationsBaseURL: process.env.VUE_APP_AUTOMATIONS_URL,
            formBuilderBaseURL: process.env.VUE_APP_FORMBUILDER_URL
        }
    };

    // Return the appropriate environment configuration
    return config[env];
}
