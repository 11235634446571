import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

const getParentLibId = () => {
  let locationUrl = new URL(document.location.href);
  if(locationUrl.searchParams.get('p')) {
     return locationUrl.searchParams.get('p')
  } else {
    return localStorage.getItem('parent_lib_id')
  }
}

const areThirdPartyCookiesEnabled = () => {
  // Try to set a third-party cookie
  document.cookie = 'testCookie=test; samesite=None; secure';

  // Check if the cookie was set successfully
  var cookiesEnabled = document.cookie.indexOf('testCookie') !== -1;

  // Clean up by deleting the test cookie
  document.cookie = 'testCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; samesite=None; secure';

  return cookiesEnabled;
}
export default createStore({
  state: {
    isAgentProfileNotCompleted:false,
    showAgentProfileAlert:false,
    isChildLibrariesLoading:false,
    isSelectedChildAgency:null,
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isRTL: false,
    color: "",
    sidebarType: "bg-white",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    siteCopyRight:'',
    childLibraryList:[],
    assigned_lib_id:localStorage.getItem('parent_lib_id') == process.env.VUE_APP_HLPT_PARENT_SUPPORT_LIB_ID ? localStorage.getItem('parent_lib_id') : '',

    user_id:localStorage.getItem('user_id'),
    support_lib_id:localStorage.getItem('support_Lib_id'),
    ticketStatusUpdatingRef:'',

    selectedChildAgencyFilter:'',
    agent_picture:'',
    frontendUnreadMsgs:0,
    queueMembers:[],
    inboxMembers:[],
    messages:{},
    selectedChatBefore:null,
    selectedChat:null,
    selectedOngoingChat:null,
    selectedArchive:null,
    selectedTicket:null,
    selectedOtherTicket:null,
    selectedMentionTicket:null,
    selectedCreatedByYouTicket:null,
    selectedReassignedTicket:null,
    selectedUnassignedTicket:null,
    selectedAwaitingReplyTicket:null,
    selectedHlptTicket:null,
    selectedAwaitingReplyTicket:null,
    isSelectedChatLoading:false,
    isSelectedArchiveLoading:false,
    isSelectedTicketLoading:false,
    isSelectedOtherTicketLoading:false,
    isSelectedMentionTicketLoading:false,
    isSelectedCreatedByYouTicketLoading:false,
    isSelectedReassignedTicketLoading:false,
    isSelectedUnassignedTicketLoading:false,
    isSelectedAwaitingReplyTicketLoading:false,
    isSelectedSearchLoading:false,
    isSelectedOtherHlptTicketLoading:false,
    isSelectedAwaitingReplyHlptTicketLoading:false,
    isAiWidget:0,

    openIntegratedChats:false,
    

    socket:'',
    scrollToBottom:false,
    display_name:'',
    isticketCreating:false,
    isticketTypeCreating:false,
    isDirectTicketCreating:false,


    isArchiveSearch:false,
    searchArchive:false,
    isArchiveSearching:false,
    archiveSortByFilter:'newest',
    archiveEmailFilter:'',
    archivePerpage:20,
    allChatsSortByFilter:'',
    archiveChatsTotalRecords:0,
    archiveChatsPage:1,
    archiveChats:[],

    inboxChatsType:'all',

    isDisplayInboxChats:true,
    isDisplaySuperviseChats:true,

    superviseChats:{},
    allOnGoingChats:[],
    isSuperviseChatsLoading:true,
    isAllOnGoingChatsLoading:true,


    ticketsTotalRecords:0,
    ticketsPage:1,
    isTicketSearch:false,
    searchTicket:false,
    isTicketSearching:false,
    ticketSortByFilter:'newest',
    ticketEmailFilter:'',
    ticketStatusByFilter:'',
    ticketCategoryFilter:'',
    ticketTierFilter:'',
    ticketCreateMethodFilter:'',
    ticketRefFilter:'',
    ticketCreateStatusFilter:'',
    ticketsPerpage:20,
    tickets:[],
    fetchTickets:false,
    isLoadingTickets:false,

    otherTicketsTotalRecords:0,
    otherTicketsPage:1,
    isOtherTicketSearch:false,
    searchOtherTicket:false,
    isOtherTicketSearching:false,
    otherTicketSortByFilter:'newest',
    otherTicketEmailFilter:'',
    otherTicketStatusByFilter:'',
    otherTicketCategoryFilter:'',
    otherTicketTierFilter:'',
    otherTicketCreateMethodFilter:'',
    otherTicketRefFilter:'',
    otherTicketCreateStatusFilter:'',
    otherTicketsPerpage:20,
    otherTickets:[],
    fetchOtherTickets:false,
    isLoadingOtherTickets:false,

    hlptTicketsTotalRecords:0,
    hlptTicketsPage:1,
    isHlptTicketSearch:false,
    searchHlptTicket:false,
    isHlptTicketSearching:false,
    hlptTicketSortByFilter:'newest',
    hlptTicketEmailFilter:'',
    hlptTicketStatusByFilter:'',
    hlptTicketCategoryFilter:'',
    hlptTicketTierFilter:'',
    hlptTicketCreateMethodFilter:'',
    hlptTicketRefFilter:'',
    hlptTicketCreateStatusFilter:'',
    hlptTicketsPerpage:20,
    hlptTickets:[],
    fetchHlptTickets:false,
    isLoadingHlptTickets:false,

    mentionTicketsTotalRecords:0,
    mentionTicketsPage:1,
    isMentionTicketSearch:false,
    searchMentionTicket:false,
    isMentionTicketSearching:false,
    mentionTicketSortByFilter:'newest',
    mentionTicketEmailFilter:'',
    mentionTicketStatusByFilter:'',
    mentionTicketCategoryFilter:'',
    mentionTicketTierFilter:'',
    mentionTicketCreateMethodFilter:'',
    mentionTicketRefFilter:'',
    mentionTicketCreateStatusFilter:'',
    mentionTicketsPerpage:20,
    mentionTickets:[],
    fetchMentionTickets:false,
    isLoadingMentionTickets:false,

    createdByYouTicketsTotalRecords:0,
    createdByYouTicketsPage:1,
    isCreatedByYouTicketSearch:false,
    searchCreatedByYouTicket:false,
    isCreatedByYouTicketSearching:false,
    createdByYouTicketSortByFilter:'newest',
    createdByYouTicketEmailFilter:'',
    createdByYouTicketStatusByFilter:'',
    createdByYouTicketCategoryFilter:'',
    createdByYouTicketTierFilter:'',
    createdByYouTicketCreateMethodFilter:'',
    createdByYouTicketRefFilter:'',
    createdByYouTicketCreateStatusFilter:'',
    createdByYouTicketsPerpage:20,
    createdByYouTickets:[],
    fetchCreatedByYouTickets:false,
    isLoadingCreatedByYouTickets:false,


    reassignedTicketsTotalRecords:0,
    reassignedTicketsPage:1,
    isReassignedTicketSearch:false,
    searchReassignedTicket:false,
    isReassignedTicketSearching:false,
    reassignedTicketSortByFilter:'newest',
    reassignedTicketEmailFilter:'',
    reassignedTicketStatusByFilter:'',
    reassignedTicketCategoryFilter:'',
    reassignedTicketTierFilter:'',
    reassignedTicketCreateMethodFilter:'',
    reassignedTicketRefFilter:'',
    reassignedTicketCreateStatusFilter:'',
    reassignedTicketsPerpage:20,
    reassignedTickets:[],
    fetchReassignedTickets:false,
    isLoadingReassignedTickets:false,

    unassignedTicketsTotalRecords:0,
    unassignedTicketsPage:1,
    isUnassignedTicketSearch:false,
    searchUnassignedTicket:false,
    isUnassignedTicketSearching:false,
    unassignedTicketSortByFilter:'newest',
    unassignedTicketEmailFilter:'',
    unassignedTicketStatusByFilter:'',
    unassignedTicketCategoryFilter:'',
    unassignedTicketTierFilter:'',
    unassignedTicketCreateMethodFilter:'',
    unassignedTicketRefFilter:'',
    unassignedTicketCreateStatusFilter:'',
    unassignedTicketsPerpage:20,
    unassignedTickets:[],
    fetchUnassignedTickets:false,
    isLoadingUnassignedTickets:false,

    awaitingReplyTicketsTotalRecords:0,
    awaitingReplyTicketsPage:1,
    isAwaitingReplyTicketSearch:false,
    searchAwaitingReplyTicket:false,
    isAwaitingReplyTicketSearching:false,
    awaitingReplyTicketSortByFilter:'newest',
    awaitingReplyTicketEmailFilter:'',
    awaitingReplyTicketStatusByFilter:'',
    awaitingReplyTicketCategoryFilter:'',
    awaitingReplyTicketTierFilter:'',
    awaitingReplyTicketCreateMethodFilter:'',
    awaitingReplyTicketRefFilter:'',
    awaitingReplyTicketCreateStatusFilter:'',
    awaitingReplyTicketsPerpage:20,
    awaitingReplyTickets:[],
    fetchAwaitingReplyTickets:false,
    isLoadingAwaitingReplyTickets:false,

    awaitingReplyHlptTicketsTotalRecords:0,
    awaitingReplyHlptTicketsPage:1,
    isAwaitingReplyHlptTicketSearch:false,
    searchAwaitingReplyHlptTicket:false,
    isAwaitingReplyHlptTicketSearching:false,
    awaitingReplyHlptTicketSortByFilter:'newest',
    awaitingReplyHlptTicketEmailFilter:'',
    awaitingReplyHlptTicketStatusByFilter:'',
    awaitingReplyHlptTicketCategoryFilter:'',
    awaitingReplyHlptTicketTierFilter:'',
    awaitingReplyHlptTicketCreateMethodFilter:'',
    awaitingReplyHlptTicketRefFilter:'',
    awaitingReplyHlptTicketCreateStatusFilter:'',
    awaitingReplyHlptTicketsPerpage:20,
    awaitingReplyHlptTickets:[],
    fetchAwaitingReplyHlptTickets:false,
    isLoadingAwaitingReplyHlptTickets:false,

    fetchByTicketConversationRef:'',


    searchTotalRecords:0,
    searchPage:1,
    isSearch:false,
    searchTicket:false,
    isSearching:false,
    searchSortByFilter:'',
    searchEmailFilter:'',
    searchStatusByFilter:'',
    searchCategoryFilter:'',
    searchTagFilter:'',
    searchTierFilter:'',
    searchCreateMethodFilter:'',
    searchTicketRefFilter:'',
    searchCreateStatusFilter:'',
    searchPerpage:20,
    searchRecords:[],
    searchCompanyFilter:null,
    searchCtFromFilter:'',
    searchCtToFilter:'',
    searchCustomerNameFilter:'',
    searchCustomerEmailFilter:'',
    filterSearchCompanies:'',
    filterSearchTeammates:'',
    filterSearchCategory:'',
    filterSearchTag:'',
    searchDateFilter:'',
    searchFilterType:'',
    searchTicketConversationRefFilter:'',
    selectedSearch: {},
    searchQuery:'',
    searchTicketAssignedTo:'',
    searchCSATFilter:[],
    searchDate:[],
    initSearchRecords:false,
    fetchSearchRecords:false,
    searchAdditionalFilters:[],

    assignmentAgentList:[],
    isAssignmentAgentListLoading:false,

   
    isChatTakeOverProcessing:false,
    isTicketAssignmentLoading:false,

    
    isAddingLeadTags:false,
    isLoadingLeadTags:false,
    tags:[],
    isLoadingArchieve: false,
    is_away:null,
    show_alert:false,
    awayAgents:[],
    agentModeUpdated:false,

    selectedChats:[],

    //frontend chat
    isModalChat:false,
    chatStarted:false,
    previousChat:[],
    frontendMessages:[],
    connection_id:'',
    chatEnded:false,
    widgetChatForm:false,
    companyName:'Chat',
    frontendCompanyName:'Knowledge Base',
    companyLogo:'',
    visitor_name: areThirdPartyCookiesEnabled() ? localStorage.getItem('visitor_name') : '',
    visitor_email: areThirdPartyCookiesEnabled() ? localStorage.getItem('visitor_email') : '',
    visitor_question: '',
    conv_rating: '',
    isConnecting:false,
    isTyping:false,
    areThirdPartyCookiesEnabled:false,
    visitor_connection_conversation_ref:'',
    visitor_connection_agent_ref:'',
    visitor_connection_queue_ref:'',
    visitor_connection_device_ref:'',
    visitor_connection_visitor_ref:'',
    visitor_connection_visitor_con_id:'',
    fetchArchives:false,
    agent_name:'',
    agentList:[],
    isPreviousMessagesFetching:false,

    showCompactBottomToolbar:true,
  
    widgetDashboard:null,
    widgetMessages:null,
    widgetContactus:null,
    widgetHelplibrary:null,
    widgetNewsfeed:null,

    isOtherTicketMenuLinkFlagged:false,
    isArchivesMenuLinkFlagged:false,
    isHlptTicketMenuLinkFlagged:false,
    isAwaitingHlptTicketMenuLinkFlagged:false,

    advancedWidgetIndex:'HomeAdvancedDashboard',
    advancedWidgetActiveRoute:'HomeAdvancedDashboard',
    compactWidgetActiveRoute:'HomeAdvancedDashboard',
    customLinkData:null,
    selectAllRecords:false,

    userPermissions: {
      canManageGeneralAndSecuritySettings: true,
      canManageTeammatesSeatsAndPermissions: true,
      canChangeTeammatesStatus: true,
      canManageMessengerSettings: true,
      canManageWidgetSettings: true,
      canManageTicketSettings: true,
      canManageChatSettings: true,
      canCreateAndUpdateHelpCenterArticles: true,
      canManageAndPublishHelpCenterArticles: true,
      canCreateAndUpdateNewsArticles: true,
      canManageAndPublishNewsArticles: true,
      canCreateAndUpdateCoursesArticles: true,
      canManageAndPublishCoursesArticles: true,
      canCreateAndUpdateSopArticles: true,
      canManageAndPublishSopArticles: true,
      canTakeChats: true,
      canSuperviseChats: true,
      canViewChatArchives: true,
      canReplyToTickets: true,
      canViewExistingTickets: true,
      canCreateAndEditAnAutomation: true,
      canCreateAndEditFormbuilder: true,
      canInviteNewUsers: true,
      canUpdateUserRoles: true
    },

    fetchTeams:false,

    chat_ref_uid:'',
    chat_ended_by:'',
    showToast:{},
    enable_inbox_notifications:true,
  
    parent_lib_id: getParentLibId(),
    bootstrap,
    lastFailedArticleSearch: {
      id: "",
    },
    lastSuccessArticleSearch: {
      id: ""
    },
    searchedKeyword: {
      query: ""
    },
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector("#app");

      if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      } else if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else if (sidenav_show.classList.contains("g-sidenav-show") && window.innerWidth < 1200) {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    setSidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleDefaultLayout(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    setLastFailedArticleSearch(state, value) {
      state.lastFailedArticleSearch.id = value;
    },
    setLastSuccessArticleSearch(state, value) {
      state.lastSuccessArticleSearch.id = value;
    },
    setSearchedKeyword(state, value) {
      state.searchedKeyword.query = value;
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("setSidebarType", payload);
    },
    async updateLastFailedArticleSearch(context, payload) {
      context.commit("setLastFailedArticleSearch", payload.data);
    },
    async updateLastSuccessArticleSearch(context, payload) {
      context.commit("setLastSuccessArticleSearch", payload.data);
    },
    async updateSearchedKeyword(context, payload) {
      console.log('payload', payload)
      context.commit("setSearchedKeyword", payload);
    },
  },
  getters: {},
});
